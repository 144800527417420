import React from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';

export const Company = () => {
  return (
    <Box
      id="company"
      className="box box-color-2"
      sx={{
        textAlign: 'center',
        display: 'flex'
      }}
    >
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid className='box-overflow' container direction="row" sx={{ maxWidth: '1050px' }}>
          <Grid item xs={12} sx={{ marginBottom: '30px' }}>
            <Typography className="text-white section-header">Company</Typography>
          </Grid>
          <Grid item xs={12} className="scroll-container">
            <Typography className="section-text text-white">
              Webber Structures has been operating in Northern Virginia since 2003, offering construction services rooted in honesty, open communication, reliability, and experience. We strive to
              complete every project in a cost-effective manner, ensuring timely completion within the designated budget. Our team comprises highly experienced and talented professionals, as well as
              safety-conscious sub-contractors who exhibit the highest level of professionalism on every work site.
            </Typography>
            <Typography className="section-text text-white">
              As a customer-oriented general contractor, we prioritize the unique needs of each client, ensuring that we provide tailored solutions to meet their specific desires and budget. At Webber
              Structures, we take pride in delivering peace of mind to our clients when it comes to home building projects. We offer a plethora of samples, references, and pictures of our past
              successful projects to give our clients confidence in our ability to deliver quality work. When you hire Webber Structures, you can trust that you are receiving top-notch service and a
              team that is dedicated to making your project a success.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Company;
