import { useState, useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Box, Container, AppBar, Stack, Toolbar, Typography, IconButton, Drawer, List, ListItem, Divider, ListItemText, useMediaQuery } from '@mui/material';
import { Logo } from './site/Logo';
import { ScrolledLogo } from './site/ScrolledLogo';
import { Menu as MenuIcon } from '@mui/icons-material';

let navbarLinks = [
  { id: 1, label: 'Mission', href: '/#mission' },
  { id: 2, label: 'Company', href: '/#company' },
  { id: 3, label: 'Capabilities', href: '/#capabilities' },
  { id: 4, label: 'Show Case', href: '/#showcase' },
  { id: 5, label: 'Testimonials', href: '/#testimonials' },
  { id: 6, label: 'Contact', href: '/#contact' },
  { id: 7, label: 'FAQ', href: '/#faq' }
];

export const NavBar = () => {
  const isMobile = useMediaQuery('(max-width:840px)');

  const IN = 0.3;
  const OUT = 2;

  const [scrolled, setScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [easeInOut, setEaseInOut] = useState([IN, OUT]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (isOpen) => () => {
    setIsDrawerOpen(isOpen);
  };

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset;
      if (currentPosition < 50) {
        setIsVisible(true);
        setEaseInOut([IN, OUT]);
        setScrolled(false);
      } else {
        setIsVisible(false);
        setEaseInOut([OUT, IN]);
        setScrolled(true);
      }
    }

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrolled]);

  return (
    <Box id="site-nav" position="fixed" sx={{ zIndex: '100' }}>
      <AppBar className={scrolled ? 'scrolled-app-bar' : 'app-bar'}>
        <Container>
          <Toolbar>
            <Typography sx={{ flexGrow: 1, opacity: isVisible ? 1 : 0, transition: `opacity ${easeInOut[1]}s ease-in-out`, position: 'absolute', left: 0 }}>
              <NavLink to={navbarLinks[0].href}>
                <Logo />
              </NavLink>
            </Typography>
            <Typography sx={{ flexGrow: 1, opacity: isVisible ? 0 : 1, transition: `opacity ${easeInOut[0]}s ease-in-out`, left: 0 }}>
              <NavLink to={navbarLinks[0].href}>
                <ScrolledLogo />
              </NavLink>
            </Typography>
            {isMobile && (
              <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            )}
            <Stack direction="row" spacing={0}>
              {!isMobile &&
                navbarLinks
                  .filter((navbarLink) => ![1, 6, 7].includes(navbarLink.id))
                  .map((navbarLink) => (
                    <NavLink key={navbarLink.id} to={navbarLink.href}>
                      <Typography className="app-bar-link-text">{navbarLink.label}</Typography>
                    </NavLink>
                  ))}
              {!isMobile && (
                <>
                  <Typography className="app-bar-button app-bar-partition">|</Typography>
                  <NavLink to={navbarLinks[5].href}>
                    <Typography className="app-bar-link-text">{navbarLinks[5].label}</Typography>
                  </NavLink>
                  <NavLink to={navbarLinks[6].href}>
                    <Typography className="app-bar-link-text">{navbarLinks[6].label}</Typography>
                  </NavLink>
                </>
              )}
            </Stack>
          </Toolbar>
          {isMobile && (
            <>
              <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <List>
                  {navbarLinks
                    .filter((navbarLink) => ![1, 6, 7].includes(navbarLink.id))
                    .map((navbarLink) => (
                      <NavLink key={navbarLink.id} to={navbarLink.href}>
                        <ListItem onClick={toggleDrawer(false)}>
                          <ListItemText primary={navbarLink.label} />
                        </ListItem>
                      </NavLink>
                    ))}
                  <Divider />
                  <NavLink to={navbarLinks[5].href}>
                    <ListItem onClick={toggleDrawer(false)}>
                      <ListItemText primary={navbarLinks[5].label} />
                    </ListItem>
                  </NavLink>
                  <NavLink to={navbarLinks[6].href}>
                    <ListItem onClick={toggleDrawer(false)}>
                      <ListItemText primary={navbarLinks[6].label} />
                    </ListItem>
                  </NavLink>
                </List>
              </Drawer>
            </>
          )}
        </Container>
        <Outlet />
      </AppBar>
      <Toolbar />
    </Box>
  );
};
