import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Capabilities } from '../components/home/Capabilities';
import { ShowCase } from '../components/home/ShowCase';
import { Company } from '../components/home/Company';
import { Mission } from '../components/home/Mission';
import { Testimonials } from '../components/home/Testimonials';
import { Contact } from '../components/home/Contact';
import { Footer } from '../components/home/Footer';
import { Box } from '@mui/material';
import FAQ from '../components/home/FAQ';

export const HomePage = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsVisible(true), 0);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Box
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out'
        }}
      >
        <Mission />
        <Company />
        <Capabilities />
        <ShowCase />
        <Testimonials />
        <Contact />
        <FAQ />
        <Footer />
        <Outlet />
      </Box>
    </>
  );
};
