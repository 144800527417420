import React from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import Faq from 'react-faq-component';

const faqs = {
  rows: [
    {
      title: 'How close do you stay to time lines and budgets?',
      content:
        "The budget and a reasonable time frame are stated in the contract, which is signed by all parties with copies provided before break ground or start any project. There is an Act of God provision in the contract. Construction during a hurricane is not only dangerous, but not likely to produce a good finished project, but check with our references and you can see for yourself if it's not due to nature or outside forces, we do what we say, when we say."
    },
    {
      title: 'How are payments made, and when are they made?',
      content:
        'These terms are broken down in the contract based on progress with a completion date included. Typically we take deposit for materials depending in a kind of project, and the rest of the payments are determined beforehand. Once the work is completed to a pre-determined point, a pre-determined payment is made, with final payment due upon completion.'
    },
    {
      title: 'How many of your customers come from repeat business or from referrals from existing or previous customers?',
      content:
        "We are very blessed to say that over 50% of our customers come to us or are referred to us from customers we've performed for in the past. As you will see when you check our references, there is a reason for such a loyal client base."
    },
    {
      title: 'What about permits?',
      content:
        'Permits are required for most construction items; some items like driveways, walkways, fences do not require a permit. We will obtain all necessary permits, handle all inspections and make sure that everything is built under code.'
    },
    {
      title: 'What should I be asking my contractor?',
      content:
        "A few key questions to ask could be: 'How long have you been in business?', 'Have you ever done a project like this one, if so tell me about it?', 'Do you have insurance?', Is your crew experienced? With years of experience under our belts, WEBBER STRUCTURES is happy to provide all of these answers, and provide references if need be."
    },
    {
      title: 'What about materials?',
      content:
        'Important materials are specified in the contract. All materials are covered, however, as substitutions are sometimes unavoidable (lots of bricks run out, for example, colors of materials are sometimes no longer made), we make it clear that an equal substitution will be made but only upon your approval. For items such as lighting fixtures or doorknobs that you have not chosen when the contract is signed, a budget is determined in the contract to cover exactly what will be spent on them.'
    },
    {
      title: 'What is a change order?',
      content:
        'A change order is a written statement signed by the customer authorizing the contractor to do additional work not included in the original contract. The change order should be signed before the additional work is started, but often it is not in order to keep the project moving. The amount specified is due when the project is completed. A change order also may be written when a contractor comes across any unforeseen damage or problem. In those cases, contractor budget has a contingency provision to support expenses to fix the problem unless if problem is major force situation.'
    }
  ]
};

const styles = {
  bgColor: 'transparent',
  titleTextColor: 'white',
  rowTitleColor: 'white',
  rowContentColor: 'white',
  arrowColor: 'white'
};

export const FAQ = () => {
  return (
    <Box
      id="faq"
      className="box box-color-2"
      sx={{
        display: 'flex'
      }}
    >
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid className='box-overflow' container direction="row" sx={{ maxWidth: '1050px' }}>
          <Grid item xs={12} sx={{ marginBottom: '30px' }}>
            <Typography className="section-header" sx={{ textAlign: 'center', margin: '0px 0px 60px 0px', color: 'white' }}>
              FAQ
            </Typography>
          </Grid>
          <Grid item xs={12} className="scroll-container">
            <Faq data={faqs} styles={styles} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQ;
