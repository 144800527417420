import 'animate.css';
import { Container, Box, Grid, Typography } from '@mui/material';
import { DisplayCard } from './DisplayCard';

import capability_new_construction from '../../assets/img/capabilities/capability_new_construction.webp';
import capability_masonry from '../../assets/img/capabilities/capability_masonry.webp';
import capability_foundation from '../../assets/img/capabilities/capability_foundation.webp';
import capability_framing from '../../assets/img/capabilities/capability_framing.webp';
import capability_remodel from '../../assets/img/capabilities/capability_remodel.webp';
import capability_additions from '../../assets/img/capabilities/capability_additions.webp';

const capabilities = [
  {
    title: 'New Construction',
    description: 'Crafting your dream home, one brick at a time',
    imgUrl: capability_new_construction
  },
  {
    title: 'Home Additions and Expansions',
    description: 'Elevate your living space with seamless additions',
    imgUrl: capability_additions
  },
  {
    title: 'Home Remodeling and Renovation',
    description: 'Transforming houses into dream homes',
    imgUrl: capability_remodel
  },
  {
    title: 'Foundation and Structural Work',
    description: 'Solid foundations for a safe and lasting home',
    imgUrl: capability_foundation
  },
  {
    title: 'Framing and Carpentry',
    description: 'Precision craftsmanship for the heart of your home',
    imgUrl: capability_framing
  },
  {
    title: 'Masonry and Concrete Work',
    description: 'Strength and elegance in every stone, brick, and pour',
    imgUrl: capability_masonry
  }
];

export const Capabilities = () => {
  return (
    <Box
      id="capabilities"
      className="box box-color-1"
      sx={{
        textAlign: 'center',
        display: 'flex'
      }}
    >
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid className='box-overflow' container direction="column">
          <Grid item xs={12} sx={{ marginBottom: '30px' }}>
            <Typography className="section-header">Capabilities</Typography>
            <Typography className="section-text">At Webber Structures, we turn dreams into reality with expert craftsmanship, precision, and an array of capabilities.</Typography>
            <Grid item xs={12} className="scroll-container">
              <Grid container direction="row" spacing={2}>
                {capabilities.map((capability, index) => (
                  <Grid item xs={6} key={index}>
                    <DisplayCard {...capability} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
