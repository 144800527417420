import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { NavBar } from './components/NavBar';
import { HomePage } from './views/HomePage';
import { ErrorPage } from './views/ErrorPage';
import './App.css';

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        if (elem.id === 'mission') {
          window.scrollTo({
            top: 0
          });
        } else {
          elem.style.scrollMargin = '37px';
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    }
  }, [location]);

  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
