import React from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';

export const Testimonials = () => {
  return (
    <Box
      id="testimonials"
      className="box box-color-2"
      sx={{
        textAlign: 'center',
        display: 'flex'
      }}
    >
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid className='box-overflow' container direction="row">
          <Grid item xs={12} sx={{ marginBottom: '30px' }}>
            <Typography className="text-white section-header">Testimonials</Typography>
          </Grid>
          <Grid item xs={12} className="scroll-container">
            <Typography className="section-text testimonial text-white" sx={{ marginTop: '60px' }}>
              "We had Webber Structures help us out with our Four Season Sunroom. Our experience with Jaime was that he maintained good communication, was reliable, and came to the site often to make
              sure the project was going smoothly and that things were being done professionally, and on time. All the sub-contractors he contracted were courteous, professional and worked diligently.
              We are happy that we selected Mr. Jaime-Walter Miranda to help do our sunroom project. His help is appreciated, and we would recommend him to others. Most of all we Love our sunroom and
              spend lots of time there."
            </Typography>
            <Typography className="section-text testimonial text-white" sx={{ marginTop: '10px' }}>
              - Mike Shadman
            </Typography>
            <Typography className="section-text testimonial text-white" sx={{ marginTop: '40px' }}>
              "Have engaged Webber Structures on several projects now including concrete driveway, sidewalk around house, sump pump installation, and electrical work. Jaime-Walter is very good to deal
              with, is responsive, and provides reasonable pricing and referrals. I notice that his workers are same. He takes good care of them and they do good work."
            </Typography>
            <Typography className="section-text testimonial text-white" sx={{ marginTop: '10px' }}>
              - Tim Jacobs
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Testimonials;
