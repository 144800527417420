import { Container, Box, Typography, Grid, Card, CardContent } from '@mui/material';

export const Contact = () => {
  return (
    <>
      <Box
        id="contact"
        className="box box-color-1"
        sx={{
          textAlign: 'center',
          display: 'flex',
        }}
      >
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid className='box-overflow' container direction="row">
            <Grid item xs={12} className="scroll-container-contact">
              <Card className="contact-container" style={{ maxWidth: 650, padding: '20px 25px', margin: '0 auto', backgroundColor: '#fbfbfb', borderRadius: '10px' }}>
                <Typography className="section-header" align="center" sx={{ marginTop: '20px' }}>
                  Contact Us
                </Typography>
                <CardContent>
                  <Typography sx={{ margin: '10px auto 60px auto', textAlign: 'center' }} variant="h5" component="p" gutterBottom>
                    Reach out to our team and we will get back with you
                  </Typography>
                  <Typography sx={{ margin: '0px auto 20px auto', wordWrap: 'break-word' }}>Ph: 703-715-7224</Typography>
                  <Typography sx={{ margin: '0px auto 20px auto', wordWrap: 'break-word' }}>jaime.walter@webberstructures.com</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
