import 'animate.css';
import { Box, Typography } from '@mui/material';
import floor_plan_loop_video from '../../assets/vid/floor_plan_loop_video.mov';

export const Mission = () => {
  return (
    <Box className="box" id="mission">
      <video className="mission-video" src={floor_plan_loop_video} autoPlay loop muted />
      <div className="mission-overlay">
        <div className="mission-statement-container">
          <Typography className="mission-statement">At Webber Structures, our mission is to bring attention to detail, craftsmanship, and precision engineering to every project</Typography>
        </div>
      </div>
    </Box>
  );
};
