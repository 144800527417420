import { Outlet } from 'react-router-dom';

export const ErrorPage = () => {
  return (
    <>
      <div>Error - Page Not Found</div>
      <Outlet />
    </>
  );
};
