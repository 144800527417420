import 'animate.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Container, Box, Grid, Typography, IconButton } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import home_elevation_1 from '../../assets/img/showcase/showcase_home_elevation_1.jpg';
import home_elevation_2 from '../../assets/img/showcase/showcase_home_elevation_2.jpg';

import new_construction_1 from '../../assets/img/showcase/showcase_new_construction_1.jpg';
import new_construction_2 from '../../assets/img/showcase/showcase_new_construction_2.jpg';
import new_construction_3 from '../../assets/img/showcase/showcase_new_construction_3.jpg';
import new_construction_4 from '../../assets/img/showcase/showcase_new_construction_4.jpg';
import new_construction_5 from '../../assets/img/showcase/showcase_new_construction_5.jpg';

import home_rebuild_1 from '../../assets/img/showcase/showcase_home_rebuild_1.jpg';
import home_rebuild_2 from '../../assets/img/showcase/showcase_home_rebuild_2.jpg';
import home_rebuild_3 from '../../assets/img/showcase/showcase_home_rebuild_3.jpg';
import home_rebuild_4 from '../../assets/img/showcase/showcase_home_rebuild_4.jpg';
import home_rebuild_5 from '../../assets/img/showcase/showcase_home_rebuild_5.jpg';

import home_addition_1 from '../../assets/img/showcase/showcase_home_addition_1.jpg';
import home_addition_2 from '../../assets/img/showcase/showcase_home_addition_2.jpg';
import home_addition_3 from '../../assets/img/showcase/showcase_home_addition_3.jpg';

import home_remodel_1 from '../../assets/img/showcase/showcase_home_remodel_1.jpg';
import home_remodel_2 from '../../assets/img/showcase/showcase_home_remodel_2.jpg';
import home_remodel_3 from '../../assets/img/showcase/showcase_home_remodel_3.jpg';
import home_remodel_4 from '../../assets/img/showcase/showcase_home_remodel_4.jpg';

import sun_room_1 from '../../assets/img/showcase/showcase_sun_room_1.jpg';
import sun_room_2 from '../../assets/img/showcase/showcase_sun_room_2.jpg';

const home_elevations = [
  {
    title: 'Elevation 1',
    img: home_elevation_1
  },
  {
    title: 'Elevation 2',
    img: home_elevation_2
  }
];

const new_constructions = [
  {
    title: 'New Construction 1',
    img: new_construction_1
  },
  {
    title: 'New Construction 2',
    img: new_construction_2
  },
  {
    title: 'New Construction 3',
    img: new_construction_3
  },
  {
    title: 'New Construction 4',
    img: new_construction_4
  },
  {
    title: 'New Construction 5',
    img: new_construction_5
  }
];

const rebuilds = [
  {
    title: 'Rebuild 1',
    img: home_rebuild_1
  },
  {
    title: 'Rebuild 2',
    img: home_rebuild_2
  },
  {
    title: 'Rebuild 3',
    img: home_rebuild_3
  },
  {
    title: 'Rebuild 4',
    img: home_rebuild_4
  },
  {
    title: 'Rebuild 5',
    img: home_rebuild_5
  }
];

const additions = [
  {
    title: 'Addition 1',
    img: home_addition_1
  },
  {
    title: 'Addition 2',
    img: home_addition_2
  },
  {
    title: 'Addition 3',
    img: home_addition_3
  }
];

const remodels = [
  {
    title: 'Remodel 1',
    img: home_remodel_1
  },
  {
    title: 'Remodel 2',
    img: home_remodel_2
  },
  {
    title: 'Remodel 3',
    img: home_remodel_3
  },
  {
    title: 'Remodel 4',
    img: home_remodel_4
  }
];

const suns = [
  {
    title: 'Sun Room 1',
    img: sun_room_1
  },
  {
    title: 'Sun Room 2',
    img: sun_room_2
  }
];

const ImageCarousel = ({ images }) => {
  let fullscreenElement = null;

  const openFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    fullscreenElement = element;
  }

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    fullscreenElement = null;
  }

  return (
    <Carousel className="carousel-img">
      {images.map((image) => (
        <div className="carousel-img">
          {fullscreenElement === image.img && (
            <IconButton
              onClick={closeFullscreen}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1001,
                fontSize: '40px',
                color: 'white'
              }}
            >
              <FullscreenExitIcon style={{ fontSize: '400px', color: 'white' }} />
            </IconButton>
          )}
          {!fullscreenElement && (
            <div
              style={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1001,
                fontSize: '40px',
                color: 'white',
                textAlign: 'center' // Ensure the text is centered under the icon
              }}
            >
              <IconButton
                onClick={() =>
                  openFullscreen(document.querySelector(`.carousel-img img[src="${image.img}"]`))
                }
              >
                <FullscreenIcon style={{ fontSize: '80px', margin: '60px', color: '#f2f2f2e9' }} />
              </IconButton>
              <Typography style={{ color: '#f2f2f2e9', fontSize: '12px', margin: '-50px' }}>
                Click to enter full screen mode
              </Typography>
            </div>

          )}
          <img src={image.img} alt={image.title} style={{ maxHeight: '100vh' }} />
        </div>
      ))}
    </Carousel>
  );




};

export const ShowCase = () => {
  return (
    <Box
      id="showcase"
      className="box box-color-1"
      sx={{
        textAlign: 'center',
      }}
    >
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid className='box-overflow' container direction="column">
          <Grid item xs={12} sx={{ marginBottom: '30px' }}>
            <Typography className="section-header">
              Show Case
            </Typography>
            <Typography className="section-text">At Webber Structures, our track record of successful projects is our foundation.</Typography>
            <Grid item xs={12} className="scroll-container">
              <Grid container direction="row" spacing={3}>
                <Typography className="showcase-title">Elevation Model</Typography>
                <ImageCarousel images={home_elevations} />
                <Typography className="showcase-title">New Construction</Typography>
                <ImageCarousel images={new_constructions} />
                <Typography className="showcase-title">Rebuild From Existing House</Typography>
                <ImageCarousel images={rebuilds} />
                <Typography className="showcase-title">Home Additions</Typography>
                <ImageCarousel images={additions} />
                <Typography className="showcase-title">Home Remodel</Typography>
                <ImageCarousel images={remodels} />
                <Typography className="showcase-title">Sunrooms</Typography>
                <ImageCarousel images={suns} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box >
  );
};