import { Grid, Stack, Typography, Box, Container, Toolbar } from '@mui/material';
import { Logo } from '../site/Logo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
    <Box className="section-shadow" sx={{ minHeight: '170px' }}>
      <Container>
        <Toolbar sx={{ paddingTop: '60px' }}>
          <Typography sx={{ flexGrow: 1 }}>
            <Logo />
          </Typography>
          <Stack>
            <Grid item>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a style={{ marginRight: '12px' }} target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCCOYwCufyTIgEK2-qbAFa8Q">
                  <FontAwesomeIcon icon={faYoutube} size="2x" color="black" />
                </a>
                <a style={{ marginRight: '12px' }} target="_blank" rel="noreferrer" href="https://www.facebook.com/webberstructures/">
                  <FontAwesomeIcon icon={faFacebook} size="2x" color="black" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/search?authuser=3&_ga=2.197559403.536061739.1605026298-999045671.1582299031&q=WEBBER+STRUCTURES&ludocid=6386516161664759522&lsig=AB86z5V5BT8J0zqviNfynCmZdp9k"
                >
                  <FontAwesomeIcon icon={faGoogle} size="2x" color="black" />
                </a>
              </div>
              <Typography className="copyright" sx={{ marginTop: '8px', fontSize: '14px' }}>
                Copyright 2023. All Rights Reserved
              </Typography>
            </Grid>
          </Stack>
        </Toolbar>
      </Container>
    </Box>
  );
};
