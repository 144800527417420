import { Grid, Typography } from '@mui/material';

export const DisplayCard = ({ title, description, imgUrl }) => {
  return (
    <Grid>
      <div className="card-img-box">
        <img className="card-img" src={imgUrl} loading="lazy" alt="Capability or Industry Card" />
        <div className="card-text-box">
          <Typography className="card-title">{title}</Typography>
          <Typography className="card-text">{description}</Typography>
        </div>
      </div>
    </Grid>
  );
};
